// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://fonts.googleapis.com/css2?family=Yatra+One&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';


.listing_title {
    display: flex;
    justify-content: center;
    align-content: center;
    background: #c1282f;
    color: white;
    padding: 8px;
    margin-bottom: 4px;
    font-family: 'Yatra One', system-ui;
    position: fixed;
    z-index: 99;
    top: 0;
    width: 100%;
}

.fb_timeline {
    margin-top: 8rem;
    position: inherit;
    text-align: center;
}

@media (min-width: 320px) and (max-width: 1024px) {
    .fb_timeline {
        position: inherit !important;
    }
}
